document.addEventListener("DOMContentLoaded", function() {
    const videoBackground = document.querySelector('.p-home__video-background');
    const videoElement = document.querySelector('.p-home__video-class');
    const content = document.getElementById('content');
    let isVideoPlaying = false;
    let hasScrolledDown = false;
    let isScrolling = false;

    if (videoBackground && videoElement) {
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                if (!isVideoPlaying) {
                    videoBackground.classList.add('show');
                    videoElement.play();
                    isVideoPlaying = true;
                }
            } else {
                if (isVideoPlaying) {
                    videoBackground.classList.remove('show');
                    videoElement.pause();
                    isVideoPlaying = false;
                }
            }
        }, {
            threshold: 0.7, // Adjust the threshold value (0.0 to 1.0) to control when the video starts to hide
        });

        observer.observe(videoBackground);
    }

    if (scrollDownArrow && content) {
        scrollDownArrow.addEventListener('click', () => {
            smoothScrollTo(content.offsetTop);
        });

        function adjustArrowMargin() {
            const viewportHeight = window.innerHeight;
            const arrowHeight = scrollDownArrow.offsetHeight;
            const marginBottom = viewportHeight - arrowHeight;

            scrollDownArrow.style.marginBottom = `${marginBottom}px`;
        }

        // Adjust the margin when the page loads
        adjustArrowMargin();
        // Adjust the margin when the window is resized
        window.addEventListener('resize', adjustArrowMargin);

        // Smooth scroll function
        function smoothScrollTo(targetPosition) {
            isScrolling = true;

            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth'
            });

            // Wait for the scroll to finish (approximate duration based on scroll behavior)
            setTimeout(() => {
                isScrolling = false;
                hasScrolledDown = true;
            }, 1000); // Adjust the duration if necessary
        }
    }
});
